@mixin primaryBtnStyle {

    outline: none;
    border: var(--button-default-border);
    background: var(--button-default-bg);
    padding: var(--button-default-padding);
    font-size: var(--button-default-font-size);
    font-weight: var(--button-default-font-weight);
    box-shadow: var(--button-default-shadow);
    font-family: inherit;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
    position: relative;

    .btn__text {
      position: relative; // Establish a new stacking context
      z-index: 1; // Higher than the pseudo-element
      color: white;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: var(--button-hover-bg);
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
      border-radius: 4px;
      z-index: 0;
    }

    &:hover::before {
      opacity: 1;
    }

    &.disabled {
      opacity: 0.6;
      cursor: not-allowed;
      // pointer-events: none;

      &::before{
        opacity: 0;
        transition: none;
      }
    }

    @media (max-width: 768px) {
      font-size: 0.875rem;

      .btn__text {
        font-size: 0.875rem;
      }
    }

    @media (max-width: 600px) {
      font-size: 0.75rem;

      .btn__text {
        font-size: 0.75rem;
      }
    }
    }
  

