/* HTML: <div class="loader"></div> */
.loader__container{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}


.loader__content {
    height: 4px;
    width: 130px;
    --c:no-repeat linear-gradient(#6100ee 0 0);
    background: var(--c),var(--c),#d7b8fc;
    background-size: 60% 100%;
    animation: l16 3s infinite;
  }
  @keyframes l16 {
    0%   {background-position:-150% 0,-150% 0}
    66%  {background-position: 250% 0,-150% 0}
    100% {background-position: 250% 0, 250% 0}
  }

