

:root{
  --primary-violet: #524A9A;
  --primary-violet-light: #665DC0;
  --link-hover: #757575;
--primary-button-violet: linear-gradient(180deg, #665dc0 0%, #524a9a 100%);
  --primary-button-hover-violet:  linear-gradient(180deg, #8476D1 0%, #6C63A4 100%);
  --primary-button-active-violet: linear-gradient(180deg, #524a9a 0%, #665dc0 100%);
  --primary-button-black: linear-gradient(180deg, #1b1a22 0%, #0c0924 100%);
  --primary-button-hover-black: linear-gradient(180deg, #0c0924 0%, #1b1a22 100%);
  --primary-button-active-black: linear-gradient(180deg, #0c0924 0%, #1b1a22 100%);

  --primary-200: #C2BEE6;
  --primary-500: #665DC0;
  --danger-500: #D32029;
  --neutral-300: #CBCBCB;
  --neutral-500: #757575;
  --neutral-50: #F6F6F6;
  --neutral-100: #EEEEEE;
  --neutral-750: #292929;
  --neutral-850: #1A1A1A;
  --neutral-900: #141414;
  --input-border-radius: 4px;
  --input-border-width: 1px;
  --input-error-border: 1.5px solid var(--danger-500);
  --input-empty-border: 1.5px solid var(--neutral-900);
  --input-default-bg: var(--neutral-500);
  --input-default-border: 1.5px solid #EEEEEE;
  --input-default-color: var(--neutral-500);
  --input-hover-bg: var(--neutral-50);
  --input-hover-border: 1px solid var(--neutral-50);
  --input-hover-color: var(--neutral-500); 
  --input-active-bg: var(--neutral-50);
  --input-active-border: 1.5px solid var(--primary-500);
  --input-active-color: var(--neutral-300);

  --button-default-bg: linear-gradient(180deg, #665DC0 0%, #524A9A 100%);
  --button-default-text-color: #EEEEEE;
  --button-default-border: none;
  --button-default-border-radius: var(--input-border-radius);
  --button-default-font-weight: 400;
  --button-default-font-size: 0.875rem;
  --button-default-padding: 15px 15px 15px 15px;
  --button-default-shadow: 0px 2px 10px 0px #0000001C;

  --button-hover-bg: linear-gradient(180deg, #524A9A 0%, #665DC0 167.02%);
  --button-hover-shadow: 0px 2px 10px 0px #0000001C;

  --tooltip-light-border: 1px solid #0000001A;
  --tooltip-light-background: #FFFFFF;
  --tooltip-light-shadow: 0px 4px 12px 0px #0000001A;
  --tooltip-light-padding: 0.75rem 1.8rem;
  --tooltip-light-color: var(--danger-500);
  --tooltip-light-border-radius: 8px;
  --tooltip-dark-border: 1px solid #0000001A;
  --tooltip-dark-background: #000000;

  
  --button-padding: 15px 15px 15px 15px;

  --modal-text-size: 0.9375rem;
  --modal-text-weight: 400;
  --modal-text-color: var(--neutral-750);
  --modal-button-font-size: 0.75rem;
  --modal-button-font-weight: 500;
  --modal-link-color: var(--primary-500);
  --modal-link-hover-color: var(--neutral-850);
  --modal-link-font-size: 0.75rem;
  --modal-link-font-weight: 400;
  --modal-light-bg: #FFFFFF;
  --modal-light-padding: 68px, 52px, 68px, 52px;
  --modal-light-border-radius: 24px;
  --modal-light-shadow: 0px 0px 20px 0px #00000014;
  --modal-light-button-bg-dark: var(--neutral-850);
  --modal-light-button-bg-light: var(--neutral-100);
  --modal-light-button-border-light: 1px solid #E2E2E2;
  --modal-light-button-border-dark: 1px solid var(--neutral-850);
  --modal-light-button-text-light: var(--neutral-900);
  --modal-light-button-text-dark: var(--neutral-100);
  --modal-button-border-radius: 4px;
  --modal-light-button-shadow-light: 0px 2px 10px 0px #00000014;
  --modal-light-button-hover-light: var(--neutral-300);
  --modal-light-button-hover-dark: var(--neutral-750);
  --modal-light-button-shadow-dark: 0px 2px 10px 0px #00000014;






}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  width: 100vw;
  height: 100%;
}

@media (max-width: 768px){
  html {
    font-size: 87.5%;
  }
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  font-family: 'Kumbh Sans', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 1rem;
  text-rendering: optimizeLegibility;

  @media (max-width: 768px){
    font-size: 1rem;
  }
}