.App{
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
    position: relative;
    color: black;
    // background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(181, 153, 255, 0.2) 100%);


}
