@import '../../commonStyles/button.scss';

.header{
    width: 100%;
    height: 80px;
    padding: 0.7rem 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // background-color: transparent;
    color: black;
}

.header__logo-link{
    width: 140px;
    color: black;
    text-align: center;

    img{
        width: 100%;
        height: auto;
        object-fit: contain;
        object-position: center;
    }
}

.btn__register, .btn__login {
    @include primaryBtnStyle;
    width: 180px;
  }

  @media (max-width: 600px) {
    .header__logo-link {
      width: 100px;
    }

    .btn__register, .btn__login {
      width: 140px;
    }
  }
  
  
