.footer{
    width: 95%;
    height: 60px;
    padding: 1.2rem 0;
    border-top: 1px solid #E2E2E2;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    .footer__description{
        font-size: 0.8rem;
        font-weight: 300;
        color: #757575;

    }
}